/* You can add global styles to this file, and also import other style files */

// ng-select theme
@import '~@ng-select/ng-select/themes/default.theme.css';

// ngx-ui-switch
@import '../node_modules/ngx-ui-switch/ui-switch.component.scss';

//Custom colors
@import "./assets/styles/colors";



//Custom bootstrap variables
$enable-validation-icons: false;


// Font configuration
$font-family-base: 'Montserrat', sans-serif;

// Texts
$body-color: map-get($grays, gray-900);

// Buttons
$btn-font-weight: 600;
$btn-border-radius: 0.5rem;
$btn-border-radius-sm: 0.5rem;
$btn-border-radius-lg: 0.5rem;
// Inputs
$input-bg: white;
$input-border-color: map-get($grays, gray-400);
$input-placeholder-color: map-get($grays, gray-500);
$input-color: map-get($grays, gray-900);
$input-font-weight: 500;

// Form label
$form-label-color: map-get($grays, gray-800);

//body
$body-bg: map-get($grays, gray-100);

// Checkbox

$form-check-input-checked-color: $primary;
$form-check-input-checked-bg-color: map-get($primaries, primary-100);

// @import '~bootstrap/scss/bootstrap';
@import "../node_modules/bootstrap/scss/bootstrap";

// Importing tipography
@import url('https://fonts.googleapis.com/css2?family=Montserrat:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap');

@import "../node_modules/bootstrap/scss/functions";
@import "../node_modules/bootstrap/scss/variables";
@import "../node_modules/bootstrap/scss/maps";
@import "../node_modules/bootstrap/scss/mixins";
@import "../node_modules/bootstrap/scss/utilities";

// Custom shades utilities
$all-colors: map-merge-multiple($primaries, $secondaries, $successs, $warnings, $dangers, $grays);
$utilities: map-merge($utilities,
    ("color": map-merge(map-get($utilities, "color"),
        (values: map-merge(map-get(map-get($utilities, "color"), "values"),
            ($all-colors ),
          ),
        ),
      ),
    ));

// Custom text buttons
@each $name,
$color in $theme-colors {
  .btn-text-#{$name} {
    color: $color;
    border: none !important;

    &:hover,
    &:active,
    &:focus {
      color: darken($color, 10%) !important;
    }
  }
}

// Icon component sizing
app-material-icon {
  display: inline-flex;
}

// Input with icon utility
.input-prefix-icon {
  position: relative;

  app-material-icon:first-child {
    color: map-get($grays, gray-500);
    position: absolute;
    top: 50%;
    left: 12px;
    transform: translate(0, -50%);
  }

  .form-control {
    padding-left: 48px;
  }
}


.input-suffix-icon {
  position: relative;

  app-material-icon:last-child {
    color: map-get($grays, gray-500);
    position: absolute;
    top: 50%;
    right: 12px;
    transform: translate(0, -50%);
  }

  .form-control {
    padding-right: 48px;
  }
}

// Custom dropdwon menu styles
.dropdown-menu {
  border-radius: 1rem;
  box-shadow: 0px 4px 6px rgba(16, 96, 165, 0.12) !important;
  border: none;

  .dropdown-item {
    display: flex;
    align-items: center;
    gap: 1rem;
    cursor: pointer;
    color: map-get($grays, gray-600);
    font-weight: 500;

    p {
      margin: 0;
    }

    &:hover,
    &:focus {
      color: map-get($grays, gray-900);
    }

    &.active,
    &:active {
      color: map-get($grays, gray-600);
    }
  }
}


// Custom button align
.btn {
  display: flex;
  justify-content: center;
  gap: 0.5rem;
  align-items: center
}

// Custom button
.btn-gray {
  color: map-get($grays, gray-600);
  background-color: map-get($grays, gray-200);

  &:hover,
  &:active {
    background-color: map-get($grays, gray-300) !important;
  }
}

// Table
.table-container {
  border: solid map-get($grays, gray-300) 1px;
  border-radius: $btn-border-radius;
  overflow: auto;
  max-height: 600px;
}

.sticky-header {
  th {
    position: sticky;
    left: 0;
    top: 0;
    z-index: 100;

    div {
      padding: 0;
      display: flex;
      gap: 0.5rem;
      align-items: center;

      div {
        cursor: pointer;
      }
    }

    div.byOrder {
      font-weight: bolder;
    }

    &:last-of-type {
      right: 0;
      top: 0;
    }
  }
}

.sticky-actions {
  tr {
    &:last-of-type {
      border-bottom-width: 0px;
    }
  }

  td {
    &:last-of-type {
      //background-color: white;
      //box-shadow: 0px 0px 0px 1px map-get($grays, gray-300);
      position: sticky;
      right: 0;
      top: 0;

      .dropdown-toggle {
        margin-left: auto;
      }
    }
  }
}

.table {
  border-collapse: collapse;
  border-spacing: 0;
  border-radius: 10px;
  // border: 1px solid map-get($grays, gray-300);

  th {
    background-color: map-get($grays, gray-200);
    color: map-get($grays, gray-700);
    font-size: 14px;
    padding: 0.5rem 0.5rem;
  }

  tr {
    &:hover {
      background-color: map-get($grays, gray-100);
    }

    &:last-of-type {
      border-bottom-width: 0px;
    }
  }

  td {
    padding: 0.2rem 0.5rem;
  }

}

.table> :not(caption)>*>* {
  // background-color: transparent !important;
  background-color: #f8f9fa00;
}

// Toogle icon remove
.dropdown-toggle {
  &::after {
    display: none !important;
  }

  &::before {
    display: none !important;
  }
}



// Custom font size
.fs-14 {
  font-size: 0.875rem;
}

.fs-12 {
  font-size: 0.75rem;
}

//Custom font weight

.fw-medium {
  font-weight: 600;
}


.main-container {
  display: flex;
  flex-direction: column;
  height: 100vh;

  .body-container {
    height: 100%;
    display: flex;
    flex-direction: column;

    .body {
      // height: 100%;
      height: auto;
      display: flex;
      flex-direction: column;
      gap: 1rem;

      .table-main-container {
        height: 100%;

        .table-container {
          height: 100%;
          min-height: 400px;
        }
      }
    }

    app-body {
      height: 100%;
    }
  }
}

// Custom badges
@each $name,
$list in $theme-colors-shades-list {
  .badge.text-bg-#{$name} {
    color: map-get($list, #{$name}-500) !important;
    background-color: map-get($list, #{$name}-100) !important;

  }
}

.badge {
  padding: 0.5rem 1rem !important;
}

.form-check-input {
  padding: 0.5rem !important;
  cursor: pointer;
}

.form-check-label {
  font-weight: 500;
}

.selected-row {
  td {
    background-color: map-get($primaries, primary-50) !important;
  }
}

@import "../node_modules/bootstrap/scss/utilities/api";

// X-Small devices (portrait phones, less than 576px)
// No media query for `xs` since this is the default in Bootstrap

// Large devices (desktops, 992px and up)
@media (min-width: 992px) {

  // Angular Calendar Styles for Week View and Day View
  // Size scrollbar in header
  .cal-day-headers {
    margin-right: 14.5px;
  }
}

// X-Large devices (large desktops, 1200px and up)
@media (min-width: 1200px) {
  .w-lg-auto {
    width: auto !important
  }
}


.pagination {
  margin: 0 !important;
  display: flex;
  align-items: center;
  justify-content: space-between;

  .page-item {
    margin: 0 0.5rem 0 0.5rem;

    a {
      border: 1px solid map-get($primaries, primary-500);
      font-weight: 600;
      color: map-get($primaries, primary-500);
      border-radius: 8px !important;
    }
  }
}

.cursor-pointer {
  cursor: pointer;
}

.ng-select .ng-select-container {
  min-height: 40px;
  border-radius: 0.375rem;
  transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
  border: 1px solid #CED4DA;
}

.ng-select.ng-select-single .ng-select-container {
  height: 40px;
  border-radius: 0.375rem;
  transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
  border: 1px solid #CED4DA;
}

ng-select.is-invalid.ng-touched .ng-select-container {
  border-color: #dc3545 !important;
}

ng-select.ng-pristine.is-invalid .ng-select-container {
  border-color: #dc3545 !important;
}

ng-select.is-invalid-in-table.ng-touched .ng-select-container {
  margin-top: 1.6rem;
}

ng-select.ng-pristine.is-invalid-in-table .ng-select-container {
  margin-top: 1.6rem;
}

ng-select.ng-select-disabled .ng-select-container {
  background-color: var(--bs-secondary-bg) !important;
}

ng-dropdown-panel.ng-dropdown-panel {
  z-index: 3000;
}

app-navbar {
  position: sticky;
  top: 0;
}

app-sidebar {
  position: fixed;
  left: 0rem;
  width: 80px;
  height: calc(100vh - 4rem);
  border-right: 1px solid #E2E3E4;
  background-color: #FFF;
  white-space: nowrap;
  z-index: 10;
}

.app-sidebar-open {
  position: absolute;
  left: 0rem;
  width: 250px;
  height: calc(100vh - 4rem);
  border-right: 1px solid #E2E3E4;
  background-color: #FFF;
}

.icon-color {
  color: #BABBBD;
}


.title {
  color: #5B99CA;
}

.amplify-tabs {
  display: none;
}

caption {
  display: none;
}

table {
  margin: 0 !important;
}

.vertical-line-ngbNav {
  border-right: 2px lightgray solid;
  padding-right: 1rem;
}

.modal-vertical-scroll {
  overflow-y: auto;
  height: 60vh;
}

.modal-vertical-scroll-table {
  overflow-y: auto;
  height: 30vh;

  table {
    height: max-content;

    th {
      position: sticky;
      top: 0;
    }

    tr {
      cursor: pointer;
    }
  }
}

.modal-horizontal-scroll {
  overflow-x: auto;
  width: 100%;
}

.modal-horizontal-scroll-none {
  overflow-x: hidden;
  width: 100%;
}

.float-right {
  float: right !important;
}

.float-left {
  float: left !important;
}

.blinking-item {
  animation: custom-blinker 2s linear infinite;
}

.tab-content {
  overflow-x: auto !important;
}

@keyframes custom-blinker {
  50% {
    border: 1px solid rgba(255, 0, 0, 1);
  }
}

.blinking-cell {
  animation: custom-blinker-cell 2s linear infinite;
}

@keyframes custom-blinker-cell {
  50% {
    background: rgba(255, 0, 0, 0.5);

  }
}

.blinking-title {
  animation: custom-blinker-title 2s linear infinite;
}

@keyframes custom-blinker-title {
  50% {
    color: rgba(255, 0, 0, 1);
  }
}

.non-editable-color {
  background: #E9ECEF !important;
  cursor: default !important;
}

tr:has(td.non-editable-color):hover td {
  background: #dee2e6 !important; /* Color de resaltado */
}

.ng-dropdown-panel {
  min-width: max-content !important;

  .ng-dopdown-panel-items {
    min-width: max-content !important;
  }
}
